import DashboardPermissionModal from "src/components/Dashboard/DashboardsModals/DashboardPermissionModal";
import DashboardManageModal from "src/components/Dashboard/DashboardsModals/DashboardManageModal";
import DashboardShareModal from "src/components/Dashboard/DashboardsModals/DashboardShareModal";
import { getCookie, removeCookie, setCookie } from "src/helpers/cookieHelper";
import TextEditorImport from "src/components/Paritals/TextEditorImport";
import PermissionDenied from "src/components/Shared/PermissionDenied";
import IframeImport from "src/components/Paritals/IframeImport";
import { useEffect, useMemo, useRef, useState } from "react";
import ImageImport from "src/components/Paritals/ImageImport";
import { Responsive, WidthProvider } from "react-grid-layout";
import { classNames } from "src/helpers/classNames";
import Button from "src/components/Shared/Button";
import Input from "src/components/Shared/Input";
import Modal from "src/components/Shared/Modal";
import { apiRequest } from "src/async/apiUtils";
import Image from "src/components/Shared/Image";
import { useParams } from "react-router-dom";
import { noFavicon } from "src/config/host";
import {
  ArrowsPointingOutIcon,
  ArrowsRightLeftIcon,
  ArrowsUpDownIcon,
  LinkIcon,
  PencilSquareIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";

const Dashboard = () => {
  const { dashboard_id } = useParams();
  const ResponsiveReactGridLayout = useMemo(
    () => WidthProvider(Responsive),
    []
  );
  const [mounted, setmounted] = useState(false);
  const [layout, setlayout] = useState([]);
  const [permission, setPermission] = useState(null);
  const [dashboardId, setDashboardId] = useState(null);
  const [selectedItem, setSelectedItem] = useState("");
  const [optionList, setOptionList] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [saveButtonShow, setSaveButtonShow] = useState(false);
  const [manageItem, setManageItem] = useState(null);
  const [sharableModalOpen, setSharableModalOpen] = useState(false);
  const [permissionDenied, setPermissionDenied] = useState(false);
  const [settings, setSettings] = useState(null);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [name, setName] = useState("");
  const [compactType, setCompactType] = useState("vertical");

  const layoutRef = useRef(null);

  const onDrop = (layouts, layoutItem, event) => {
    if (selectedItem) {
      if (layoutRef.current) {
        const dropY = event?.clientY;
        const scrollOffset = layoutRef.current.getBoundingClientRect().top;
        const newScrollPosition =
          layoutRef.current.scrollTop + (dropY - scrollOffset) - 150;

        layoutRef.current.scrollTo({
          top: newScrollPosition,
          behavior: "smooth",
        });
      }
      const newLayoutItem = {
        ...layoutItem,
        i: layouts.length + "_" + selectedItem,
        x: layoutItem.x,
        y: layoutItem.y,
      };

      const newLayout = [
        newLayoutItem,
        ...layout?.map((item) => {
          const layout = layouts?.find((it) => it?.i === item?.i);
          return { ...item, x: layout?.x, y: layout?.y };
        }),
      ];
      setlayout(newLayout);
      setSelectedItem("");
      setSaveButtonShow(true);
    }
  };
  const onDragStop = (layout) => {
    setlayout((prevLayout) =>
      layout.map((item) => {
        const prevData = prevLayout.find(
          (layoutItem) => layoutItem.i === item.i
        );

        return {
          ...prevData,
          x: item?.x,
          y: item?.y,
          w: item?.w,
          h: item?.h,
        };
      })
    );
    setSaveButtonShow(true);
  };

  const onResizeStop = (layout) => {
    setlayout((prevLayout) =>
      layout.map((item) => {
        const prevData = prevLayout.find(
          (layoutItem) => layoutItem.i === item.i
        );

        return {
          ...prevData,
          x: item?.x,
          y: item?.y,
          w: item?.w,
          h: item?.h,
        };
      })
    );
    setSaveButtonShow(true);
  };

  const onRemoveItem = () => {
    setDeleting(true);
    setlayout((prevLayout) => {
      const newLayout = prevLayout.filter((item) => item.i !== deleteId);
      return newLayout;
    });
    setDeleting(false);
    setDeleteModalIsOpen(false);
    setSaveButtonShow(true);
  };

  const loadCards = async () => {
    try {
      const { data } = await apiRequest("get", `/cards`);
      setOptionList(data.data);
    } catch (error) {
      // console.dir("ERROR:", error.message);
    }
  };

  const loadDashboard = async () => {
    try {
      if (dashboard_id || getCookie("dashboard_id")) {
        const { data } = await apiRequest(
          "get",
          `/dashboards/${dashboard_id || getCookie("dashboard_id")}`
        );
        if (!data?.data && dashboard_id) {
          setPermissionDenied(true);
        }
        setCompactType(data?.data?.compact_type || "none");
        setlayout(data?.data?.layouts || []);
        setPermission(data?.data?.permission || null);
        setDashboardId(data?.data?._id || null);
        setName(data?.data?.name || "");
      }
    } catch (error) {
      // console.dir("ERROR:", error.message);
    }
  };

  const loadSettings = async () => {
    try {
      const { data } = await apiRequest("get", `/settings`);
      setSettings(data?.data);
    } catch (error) {
      // console.dir("ERROR:", error.message);
    }
  };

  const saveData = async () => {
    try {
      setDisabled(true);
      const { data } = await apiRequest(
        "post",
        `/dashboards`,
        {
          body: {
            dashboard_id: dashboard_id || getCookie("dashboard_id"),
            name,
            compact_type: compactType,
            layouts: layout,
          },
        },
        {
          showToastMessage: true,
          isAuthorized: dashboard_id ? true : false,
        }
      );
      setlayout(data?.data?.layouts);
      setCompactType(data?.data?.compact_type || "none");
      setPermission(data?.data?.permission || null);
      setDashboardId(data?.data?._id);
      setDisabled(false);
      setCookie("dashboard_id", data?.data?._id);
      // setSharableModalOpen(true);
      setSaveButtonShow(false);
    } catch (error) {
      setDisabled(false);
    }
  };

  const handleChange = async (e) => {
    setName(e.target.value);
    setSaveButtonShow(true);
  };

  const children = useMemo(() => {
    return layout.map((itm, i) => {
      return (
        <div
          key={itm.i}
          data-grid={itm}
          className={classNames(
            "flex border-2 border-dashed border-gray-200 relative bg-white rounded",
            itm.i?.split("_")[1] === "text"
              ? ""
              : "flex-col justify-center items-center"
          )}
        >
          <div className="absolute -top-3 right-2 z-20 bg-white border border-gray-200 flex gap-x-2 justify-between py-1 px-2 rounded">
            <div className="ml-auto flex gap-x-1 items-center">
              {((dashboard_id &&
                getCookie(`access_token`) &&
                permission?.type === `editor`) ||
                !dashboard_id) && (
                <>
                  {itm.i?.split("_")[1] !== "text" && (
                    <div
                      className="hover:cursor-pointer h-6 w-6 flex group justify-center items-center rounded-md hover:bg-gray-50 bg-white transition-all"
                      onClick={(e) => {
                        setManageItem(itm);
                      }}
                    >
                      <PencilSquareIcon className="h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                    </div>
                  )}
                  <div className="cursor-move customDraggable h-6 w-6 flex group justify-center items-center rounded-md hover:bg-gray-50 bg-white transition-all">
                    <ArrowsPointingOutIcon className="h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="absolute bottom-1 left-1 z-20 bg-white flex justify-between rounded">
            {((dashboard_id &&
              getCookie(`access_token`) &&
              permission?.type === `editor`) ||
              !dashboard_id) && (
              <>
                <div
                  className="cursor-pointer flex gap-x-2 group justify-center items-center rounded-md hover:bg-red-50 p-1 bg-white transition-all"
                  onClick={(e) => {
                    setDeleteModalIsOpen(true);
                    setDeleteId(itm.i);
                  }}
                >
                  <TrashIcon className="h-5 w-5 text-red-400 group-hover:text-red-500" />
                </div>
              </>
            )}
          </div>
          <div
            className={classNames(
              "w-full mt-2 flex",
              itm.i?.split("_")[1] !== "text"
                ? "h-[calc(100%-2.5rem)] flex-col"
                : ""
            )}
          >
            {itm.i?.split("_")[1] !== "text" && (
              <div className="absolute top-2 left-4 text-lg">{itm.title}</div>
            )}
            {itm.i?.split("_")[1] === "text" ? (
              <TextEditorImport
                value={itm?.text}
                setValue={(e) => {
                  setlayout((preData) => [
                    ...preData.map((data) =>
                      data?.i === itm?.i ? { ...data, text: e } : data
                    ),
                  ]);
                  setSaveButtonShow(true);
                }}
              />
            ) : (
              <IframeImport
                optionList={optionList}
                title={itm.i?.split("_")[1]}
              />
            )}
          </div>
        </div>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboard_id, layout, optionList, permission?.type]);

  const getRootDomain = (url) => {
    if (url) {
      let domain;
      // Find & remove protocol (http, ftp, etc.) and get domain
      if (url.indexOf("://") > -1) {
        domain = url?.split("/")[2];
      } else {
        domain = url?.split("/")[0];
      }

      // Find & remove port number
      domain = domain?.split(":")[0];

      // Find & remove "?"
      domain = domain?.split("?")[0];

      // Handle "www." at the beginning of the URL
      if (domain.startsWith("www.")) {
        domain = domain.substring(4);
      }

      return domain;
    } else {
      return url;
    }
  };

  useEffect(() => {
    if (settings?.image_favicon) {
      document.querySelector(
        "link[rel*='icon']"
      ).href = `${settings.image_favicon}`;
    }
  }, [settings?.image_favicon]);

  useEffect(() => {
    setmounted(true);
    loadSettings();
    loadCards();
    loadDashboard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="h-screen w-full">
      <div className="relative w-full h-16 flex items-center justify-between shadow-md">
        <div className="flex items-center">
          {settings && (
            <>
              <div className="h-16 w-16">
                <Image
                  image={settings?.square_logo}
                  failImage={noFavicon}
                  className=""
                  alt="Domo"
                />
              </div>
              <p className="mx-4 text-3xl">+</p>
              <div className="h-16 w-auto py-3">
                <Image
                  image={settings?.image_logo}
                  failImage={noFavicon}
                  className=""
                  alt="Clearsquare.co"
                />
              </div>
            </>
          )}
          <div className="pl-5 flex gap-2">
            <button
              className="flex gap-x-3 rounded-full py-1 px-3 items-center transition-all bg-white text-gray-400 border-gray-200 hover:border-gray-400 border-[1px] hover:shadow-sm hover:text-gray-500"
              onClick={() => {
                removeCookie("dashboard_id");
                window.location.href = window.location.origin;
              }}
            >
              Create new <PlusIcon className="h-5 w-5" />
            </button>
          </div>
        </div>
        {/* <div className="w-full absolute flex items-center justify-center">
          <div className="relative w-[260px] flex items-center justify-center">
            <div className="absolute top-0 left-3 z-20 bg-[#FFB93F] h-6 w-6 rounded-full" />
            <p className="flex items-center justify-center z-10 w-full ml-6 text-2xl py-2 bg-white text-gray-500">
              mockup-tool Builder
            </p>
            <div className="absolute bottom-0 -right-2 z-0 bg-[#FF557A] h-5 w-5 rounded-full" />
          </div>
        </div> */}
        {((dashboard_id &&
          getCookie(`access_token`) &&
          permission?.type === `editor`) ||
          !dashboard_id) && (
          <div className="flex gap-x-2 mr-5">
            {saveButtonShow && (
              <Button onClick={saveData} disabled={disabled} loading={disabled}>
                {dashboardId ? "Save" : "Publish"}
              </Button>
            )}
            {!dashboard_id && permission?.type && (
              <Button onClick={() => setSharableModalOpen(true)}>
                Get share link <LinkIcon className="w-4 h-4" />
              </Button>
            )}
          </div>
        )}
      </div>
      {((dashboard_id && !permissionDenied && getCookie(`access_token`)) ||
        !dashboard_id) && (
        <>
          <div className="w-full h-full max-h-[calc(100%-4rem)] flex">
            <div className="overflow-y-scroll pb-10 w-[260px] max-h-full flex justify-center flex-wrap gap-x-4 gap-y-3 pt-4 ">
              {/* <div className="font-medium flex-1 px-4 -pb-3 text-sm text-slate-500">
                Layout Compaction Type
              </div> */}
              {/* <div className="flex space-x-2 border-b pb-3">
                <Button
                  hoverText="Vertical Compaction"
                  hoverTextPosition={"top-start"}
                  className={`${
                    compactType === "vertical" ? "!bg-sky-300 text-white" : ""
                  }`}
                  onClick={() => {
                    setSaveButtonShow(
                      compactType !== "vertical" ? true : false
                    );
                    setCompactType("vertical");
                  }}
                >
                  <ArrowsUpDownIcon className="w-5 h-5" />
                </Button>
                <Button
                  hoverText="Horizontal Compaction"
                  className={`${
                    compactType === "horizontal" ? "!bg-sky-300 text-white" : ""
                  }`}
                  onClick={() => {
                    setSaveButtonShow(
                      compactType !== "horizontal" ? true : false
                    );
                    setCompactType("horizontal");
                  }}
                >
                  <ArrowsRightLeftIcon className="w-5 h-5" />
                </Button>

                <Button
                  hoverText="No Compaction"
                  className={`${
                    compactType === "none" ? "!bg-sky-300 text-white" : ""
                  }`}
                  onClick={() => {
                    setSaveButtonShow(compactType !== "none" ? true : false);
                    setCompactType("none");
                  }}
                >
                  <ArrowsPointingOutIcon className="w-5 h-5" />
                </Button>
              </div> */}
              {optionList
                .filter(
                  (option) =>
                    !["Textbox", "Checkbox Selector", "Slicer"].includes(
                      option.name
                    )
                )
                .map((itm) => (
                  <ImageImport
                    key={itm?._id}
                    item={itm}
                    onDragStart={(e) => {
                      e.dataTransfer.setData("text/plain", "");
                      setSelectedItem(itm?.type);
                    }}
                  />
                ))}
            </div>
            <div className="w-full h-full flex justify-center bg-gray-100 px-2 lg:px-6">
              <div className="w-full h-full max-w-[1000px] py-2">
                <div
                  ref={layoutRef}
                  className="relative z-0 w-full h-full bg-white shadow-md border-gray-200 border rounded-md px-2 py-2 lg:px-4 lg:py-4 overflow-y-auto scroll-smooth overflow-x-hidden mb-2"
                >
                  <Input
                    type="text"
                    autoComplete="on"
                    name="workspace-name"
                    value={name}
                    placeholder="Enter Dashboard Title"
                    onChange={handleChange}
                    inputClassNames={`min-w-[320px] pr-4 focus:ring-0 focus:border-blue-300 bg-transparent border border-transparent flex items-center gap-x-2 !text-xl px-2 py-1 rounded transition-all duration-75 font-semibold text-gray-900 !shadow-none`}
                  />
                  <ResponsiveReactGridLayout
                    autoSize
                    isResizable
                    isDraggable
                    rowHeight={30}
                    onDrop={onDrop}
                    isDroppable={true}
                    verticalCompact={true}
                    onDragStop={onDragStop}
                    preventCollision={false}
                    measureBeforeMount={true}
                    compactType={"vertical"}
                    useCSSTransforms={mounted}
                    onResizeStop={onResizeStop}
                    draggableHandle={`.customDraggable`}
                    cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
                    droppingItem={{ i: "xx", title: "", w: 2, h: 5 }}
                    style={{ minHeight: "100%", paddingBottom: 40 }}
                  >
                    {children}
                  </ResponsiveReactGridLayout>
                </div>
              </div>
              <div className="absolute bottom-3 right-4 rounded-full bg-white shadow-md px-4 py-2">
                Powered by{" "}
                <a
                  href={settings?.site_domain}
                  target="_blank"
                  rel="noreferrer"
                  className="text-purple-950 font-semibold hover:underline"
                >
                  {getRootDomain(settings?.site_domain)}
                </a>
              </div>
            </div>
          </div>
          <DashboardManageModal
            isOpen={manageItem?.i ? true : false}
            item={manageItem}
            layout={layout}
            name={name}
            onCancel={() => setManageItem(null)}
            onSuccess={(data) => {
              setManageItem(null);
              setlayout(data);
              setSaveButtonShow(true);
            }}
            defaultOptions={{
              onCancelButtonText: "Close",
              onSuccessLoaderVisible: disabled,
              onSuccessLoaderStart: disabled,
              onSuccessButtonVisible: false,
            }}
          />
          <DashboardShareModal
            isOpen={sharableModalOpen}
            permission={permission}
            dashboardId={dashboardId}
            onCancel={() => setSharableModalOpen(false)}
            onSuccess={(data) => {
              setSharableModalOpen(false);
              setPermission(data);
            }}
          />
        </>
      )}
      {dashboard_id && !getCookie(`access_token`) && (
        <>
          <DashboardPermissionModal isOpen={true} />
        </>
      )}
      {dashboard_id && permissionDenied && (
        <>
          <PermissionDenied />
        </>
      )}
      <Modal
        title="Card"
        size="sm"
        secondaryTitle="Delete"
        isOpen={deleteModalIsOpen}
        onCancel={() => setDeleteModalIsOpen(false)}
        isLoading={isDeleting}
        onSuccess={onRemoveItem}
        defaultOptions={{
          onSuccessButtonText: "Delete",
        }}
      >
        <div className="flex flex-col gap-y-1 whitespace-nowrap px-3 py-6 text-gray-600">
          <p className="text-lg">
            Are you sure you want to{" "}
            <span className="px-1 font-semibold text-gray-700">DELETE</span>
            this card?
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default Dashboard;
